<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      v-for="(icon, index) in headerLeftIcons"
      :id="`icon-${index}`"
      :key="index"
      class="header__left-icons"
      :to="icon.route"
    >
      <feather-icon
        :icon="icon.view"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        :target="`icon-${index}`"
        :title="icon.name"
        :delay="{ show: icon.show, hide: icon.hide }"
      />
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip,
} from 'bootstrap-vue'

export default {
  name: 'HeaderLeftItems',
  components: {
    BNavbarNav,
    BNavItem,
    BTooltip,
  },
  data() {
    return {
      headerLeftIcons: [
        {
          id: 1,
          view: 'MessageSquareIcon',
          name: this.$t('side_bar.message'),
          route: '/users/message',
          show: 150,
          hide: 50,
        },
        {
          id: 2,
          view: 'CheckSquareIcon',
          name: this.$t('side_bar.task'),
          route: '/tasks/task',
          show: 150,
          hide: 50,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.nav-bookmark-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}

</style>
