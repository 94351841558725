<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!--      <bookmarks />-->
      <header-left-items/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale/>
      <dark-Toggler class="d-none d-lg-block"/>
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <!--      <notification-dropdown />-->
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import HeaderLeftItems from './components/HeaderLeftItems.vue'
import api from '@/services/api'
import { setLocalVar } from '@/util/localstorage-helper'

export default {
  components: {
    HeaderLeftItems,
    BLink,

    // Navbar Components
    BNavbarNav,
    Locale,
    // SearchBar,
    DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  // created() {
  //   localStorage.getItem('accessToken') && this.CheckLogin()
  // },
  methods: {
    // login() {
    //   const user = {
    //     username: 'admin',
    //     password: 'vu8eaajiaw'
    //   }
    //   console.log(user, 'user')
    //   api.auth.signIn(user)
    //     .then((response) => {
    //       const {
    //         token: access_token
    //       } = response.data
    //       // setLocalVar('auth__refresh__token', refresh_token)
    //       const { data } = response.data
    //       setLocalVar('accessToken', access_token)
    //       setLocalVar('userData', JSON.stringify(data))
    //       this.$router.push({ name: 'home' })
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //       if (!error.response) {
    //         this.dismissCountDown = 4
    //
    //       } else {
    //         const status = error.response.status
    //         const message = error.response.data.message
    //         this.errorMessage = error.response.data.errorMessage
    //         this.errorMessage = 'Неверный логин или пароль!'
    //         this.showDismissibleAlert = true
    //
    //         /* CLIENT AND SERVER ERROR */
    //         if (status && status >= 400 && status <= 511) {
    //           this.dismissCountDown = 4
    //         }
    //       }
    //     })
    // }
  }
}
</script>
